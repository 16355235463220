import React, { useContext } from "react";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { ReactComponent as NavRightSVG } from "@assets/nav-right.svg";
import { useHistory } from "react-router-dom";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";

import { ReactComponent as FilterSVG } from "@assets/filter.svg";
import {
  ModalCell,
  Switch,
  PrimaryButton,
  ModalSpacer,
  ModalLabel,
  ModalTextArea,
  ModalHeader,
} from "@frontend/assaia-ui";
import { URLS } from "@services/router";
import { FiltersStore } from "@stores/FiltersStore";
import { FormattedMessage, useIntl } from "react-intl";

const PtsMainModalScreen: React.FC<{ filtersStore: FiltersStore }> = ({
  filtersStore,
}) => {
  const history = useHistory();
  const { ptsStore } = useContext(HomeContext);
  const {
    ptsData: pts,
    onPtsChange,
    setSelectedPtsId,
    onSubmitPts,
    setPtsMainModalScreen,
    closeModal,
  } = ptsStore;
  const intl = useIntl();

  if (!pts) {
    return null;
  }

  const { active, description } = pts;

  const submitPts = async () => {
    const {
      aircraftFilters: { requiredAircraftTypes, excludedAircraftTypes },
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
    } = filtersStore;
    const newPts = await onSubmitPts({
      ...pts,
      filters: {
        requiredAircraftTypes,
        excludedAircraftTypes,
        inboundFlightStatus,
        outboundFlightStatus,
        requiredTurnaroundLength,
      },
    });

    if (newPts && !("id" in pts)) {
      setSelectedPtsId(newPts.id);
      history.push(`${URLS.PTS}/${newPts.id}`);
    }
    closeModal();
  };

  return (
    <>
      <ModalHeader
        onClose={closeModal}
        title={intl.formatMessage({
          defaultMessage: "PTS Settings",
          description: "PTS modal",
        })}
        showBorderBottom={false}
      ></ModalHeader>

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Enabled",
          description: "PTS modal",
        })}
        onClick={() => onPtsChange({ active: !active })}
        leftIcon={<NotificationSVG />}
      >
        <Switch active={active}></Switch>
      </ModalCell>

      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Filters",
          description: "PTS modal",
        })}
        leftIcon={<FilterSVG />}
        rightIcon={<NavRightSVG />}
        onClick={() => setPtsMainModalScreen("filters")}
      ></ModalCell>

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Description",
          description: "PTS modal",
        })}
      >
        <ModalTextArea
          value={description}
          placeholder={intl.formatMessage({
            defaultMessage: "Set description text for the PTS...",
            description: "PTS modal",
          })}
          onChange={(v) =>
            onPtsChange({
              description: v,
            })
          }
          maxLength={200}
        />
      </ModalLabel>

      <ModalSpacer />

      <PrimaryButton disabled={false} onClick={submitPts}>
        <FormattedMessage defaultMessage="Save" description="PTS modal" />
      </PrimaryButton>
    </>
  );
};

export default observer(PtsMainModalScreen);
