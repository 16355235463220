import { DutyPhone } from "@models/dutyPhone";
import { NewPts, Pts, PtsApiSpec, PtsSchedule } from "@models/pts";
import {
  camelCaseKeys,
  formatNotificationGroupLabel,
  snakeCaseKeys,
} from "./data";
import { PtsApi, TResourceValue } from "./types";
import { notificationsApiClient as ptsApiClient } from "./client";
import { cloneDeep, uniqBy, uniqWith } from "lodash";
import di from "@di";

const ptsApiUrl = () => {
  const { authority } = di.resolve("openIdConfig");
  return (new URL(authority).origin + "/api/pts/").replace("id.", "");
};

const camelCasePts = (data: any): Pts => {
  const newData = camelCaseKeys(data);
  let { requiredTurnaroundLength, inboundFlightStatus, outboundFlightStatus } =
    newData.filters;
  requiredTurnaroundLength = requiredTurnaroundLength?.toUpperCase() || null;
  inboundFlightStatus = inboundFlightStatus?.toUpperCase() || null;
  outboundFlightStatus = outboundFlightStatus?.toUpperCase() || null;
  newData.filters = {
    ...newData.filters,
    requiredTurnaroundLength,
    inboundFlightStatus,
    outboundFlightStatus,
  };

  newData.schedules = data.schedules.map((v: any) => {
    const newValue = camelCaseKeys(v);
    newValue.start.referencePoint = newValue.start.referencePoint || "sobt";
    newValue.end.referencePoint = newValue.end.referencePoint || "sobt";

    return newValue as PtsSchedule;
  });

  const airline: TResourceValue = {
    id: data.airline,
    roleName: formatNotificationGroupLabel(data.airline),
  };

  newData.airline = airline;

  return newData;
};

const snakeCasePts = ({
  schedules,
  filters,
  active,
  airline,
  description,
  ...rest
}: Pts | NewPts): PtsApiSpec["schemas"]["PTS"] => {
  const result: PtsApiSpec["schemas"]["PTS"] = {
    active,
    airline: airline.id,
    description,
  };

  if ("id" in rest) {
    result.id = rest.id;
  }

  const toLowerCase = (v: any): any | null => v?.toLowerCase() || null;

  result.filters = {
    excluded_aircraft_types: filters.excludedAircraftTypes,
    required_aircraft_types: filters.requiredAircraftTypes,
    inbound_flight_status: toLowerCase(filters.inboundFlightStatus),
    outbound_flight_status: toLowerCase(filters.outboundFlightStatus),
    required_turnaround_length: toLowerCase(filters.requiredTurnaroundLength),
  };

  result.schedules = schedules.map(({ opName, start, end, ...rest }) => ({
    ...rest,
    op_name: "",
    start: snakeCaseKeys(start),
    end: snakeCaseKeys(end),
  }));
  result.schedules = uniqBy(result.schedules, "id");

  console.log("snakeCasePts", cloneDeep({ result }));

  return result;
};

const getPtsList = () =>
  ptsApiClient.get<DutyPhone[]>(ptsApiUrl()).then((r) => {
    return r.data.map(camelCasePts).map((pts) => ({
      ...pts,
      schedules: uniqWith(pts.schedules, (a, b) => a.id === b.id),
    }));
  });

const getPtsByGroup = (group: string) =>
  ptsApiClient
    .get<Pts>(`${ptsApiUrl()}/${group}`)
    .then((r) => camelCasePts(r.data));

const createPts = (data: NewPts): Promise<Pts> =>
  ptsApiClient
    .post<Pts>(ptsApiUrl(), snakeCasePts(data))
    .then((res) => camelCasePts(res.data));

const patchPts = (data: Pts) => {
  const { id, ...rest } = data;
  return ptsApiClient
    .patch<Pts>(ptsApiUrl() + data.id, snakeCasePts(rest))
    .then((res) => camelCasePts(res.data));
};

const deletePtsList = (data: string[]) => {
  return Promise.all(data.map((id) => ptsApiClient.delete(ptsApiUrl() + id)));
};

const patchPtsList = (data: Pts[]) => {
  return Promise.all(
    data.map((v) => {
      const { id, ...rest } = v;
      return ptsApiClient.patch<Pts>(ptsApiUrl() + id, snakeCasePts(rest));
    }),
  );
};

const ptsApi: PtsApi = {
  getPtsList,
  getPtsByGroup,
  createPts,
  patchPts,
  deletePtsList,
  patchPtsList,
};

export default ptsApi;
