import React, { Component, ComponentProps, ContextType } from "react";
import { sortBy, xor, cloneDeep, merge } from "lodash";
import {
  ModalCell,
  ModalSeparator,
  Switch,
  ModalLabel,
  PrimaryButton,
  ModalIcon,
  Input,
  ModalSpacer,
  Spinner,
  ModalHeader,
  Modal,
  ModalCombobox,
} from "@frontend/assaia-ui";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";

import styles from "./style.module.scss";
import classNames from "classnames";
import ModalSmallDropdown from "@components/Common/ModalSmallDropdown";
import RedStar from "@components/Common/RedStar";
import { HomeContext } from "@services/react";
import { DeepPartial } from "@services/types";
import { observer } from "mobx-react";
import User, { NewUser } from "@models/user";
import { getConfig } from "@di";
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlContext,
  WrappedComponentProps,
} from "react-intl";

type Props = WrappedComponentProps;

type ComponentState = {
  user: User | NewUser;
  emailError: string | null;
  rolesError: string | null;
  phoneError: string | null;
};

const texts = defineMessages({
  enterEmail: {
    defaultMessage: "Please enter the email address",
    description: "user modal",
  },
  enterValidEmail: {
    defaultMessage: "Please enter a valid email address",
    description: "user modal",
  },
  userWithEmailExists: {
    defaultMessage: "User with email {email} aleady exists",
    description: "user modal",
  },
  selectRoles: {
    defaultMessage: "Please select roles",
    description: "user modal",
  },
  enterPhone: {
    defaultMessage: "Please enter a valid phone number",
    description: "user modal",
  },
  newUser: {
    defaultMessage: "New user",
    description: "user modal",
  },
  userDetail: {
    defaultMessage: "User details",
    description: "user modal",
  },
  email: {
    defaultMessage: "Email",
    description: "user modal",
  },
  fullName: {
    defaultMessage: "Full name",
    description: "user modal",
  },
  company: {
    defaultMessage: "Company",
    description: "user modal",
  },
});

class UserModal extends Component<Props, ComponentState> {
  static contextType = HomeContext;
  context!: ContextType<typeof HomeContext>;

  constructor(props: Props, context: any) {
    super(props, context);

    this.context = context;
    this.state = {
      user: cloneDeep(this.usersStore.userModalData as User | NewUser),
      emailError: null,
      rolesError: null,
      phoneError: null,
    };

    console.log(
      `User personal link: id.setup.assaia.com/s/${this.state.user?.token}`,
    );
  }

  get personalPhone() {
    return this.state.user?.phone || null;
  }

  get usersStore() {
    return this.context.usersStore;
  }

  edit(data: DeepPartial<User>) {
    this.setState({
      emailError: null,
      rolesError: null,
      user: merge(this.state.user, data),
    });
  }

  setRoles(roles: number[]) {
    const user = { ...this.state.user, roles };
    this.setState({
      user,
      emailError: null,
      rolesError: null,
    });
  }

  save = () => {
    const { intl } = this.props;
    const data = cloneDeep(this.state.user);
    const isNew = !("id" in data);

    if (!data.email) {
      this.setState({ emailError: intl.formatMessage(texts.enterEmail) });
      return;
    }
    if (isNew && !this.validateEmail(data.email)) {
      this.setState({ emailError: intl.formatMessage(texts.enterValidEmail) });
      return;
    }

    if (!this.isPhoneValid(this.personalPhone?.phone || null)) {
      this.setState({ phoneError: intl.formatMessage(texts.enterPhone) });
      return;
    }

    if (isNew && this.usersStore.users.some((u) => u.email === data.email)) {
      this.setState({
        emailError: intl.formatMessage(texts.userWithEmailExists, {
          email: data.email,
        }),
      });
      return;
    }

    if (!data.roles.length) {
      this.setState({ rolesError: intl.formatMessage(texts.selectRoles) });
      return;
    }

    this.usersStore.saveUser(data).then(() => this.close());
  };

  delete = () => {
    if (!("id" in this.state.user)) {
      return;
    }
    this.usersStore.deleteUsers([this.state.user.id]).then(() => this.close());
  };

  resetPassword = () => {
    if (!("id" in this.state.user)) {
      return;
    }
    this.usersStore
      .resetPassword([this.state.user.id])
      .then(() => this.close());
  };

  resetOTPToken = () => {
    if (!("id" in this.state.user)) {
      return;
    }
    this.usersStore
      .resetOTPToken([this.state.user.id])
      .then(() => this.close());
  };

  validateEmail = (email: string) => {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  isPhoneValid(phone: string | null) {
    if (!phone) {
      return true;
    }

    return !phone.includes("_");
  }

  sendPersonalLink() {
    const { userModalData: user, sendPersonalLink } = this.usersStore;
    if (user && "id" in user) {
      sendPersonalLink(user.id);
    }
  }

  onPhoneChange = (value: string) => {
    const { userModalData: user } = this.usersStore;
    this.edit({
      phone: { phone: value, active: user?.phone?.active || true },
    });
  };

  close = () => {
    this.usersStore.editUser();
  };

  render() {
    const {
      enableOTPTokenReset,
      enableResendUserPersonalLink,
      enableDutyPhones,
      phonesMask,
    } = getConfig();
    const { user, emailError, phoneError, rolesError } = this.state;
    const { roles, loading } = this.usersStore;
    const { intl } = this.props;

    const isNew = !("id" in user);

    const title = isNew
      ? intl.formatMessage(texts.newUser)
      : user.profile.full_name || user.email;
    const sendLinkEnabled =
      this.personalPhone !== null &&
      this.isPhoneValid(this.personalPhone.phone);

    let rolesOptions: ComponentProps<typeof ModalCombobox>["options"] =
      roles.map((r) => ({
        title: r.description || "",
        id: r.id.toString(),
      }));
    rolesOptions = sortBy(rolesOptions, (v) => v.title.toLowerCase());

    return (
      <IntlContext.Consumer>
        {(intl) => (
          <Modal onClose={this.close}>
            {loading && (
              <div className={styles.userModalOverlay}>
                <Spinner className={styles.userModalLoader} />
              </div>
            )}
            <ModalHeader title={title} onClose={this.close}>
              {!isNew && (
                <ModalSmallDropdown
                  trigger={
                    <ModalIcon onClick={() => {}}>
                      <DotsSVG />
                    </ModalIcon>
                  }
                >
                  <ModalCell
                    onClick={this.delete}
                    title={
                      <span className={styles.red}>
                        <FormattedMessage
                          defaultMessage="Delete"
                          description="user modal"
                        />
                      </span>
                    }
                  />
                  <ModalCell
                    onClick={this.resetPassword}
                    title={
                      <span>
                        <FormattedMessage
                          defaultMessage="Reset password"
                          description="user modal"
                        />
                      </span>
                    }
                  />
                  {enableResendUserPersonalLink && (
                    <ModalCell
                      className={classNames({
                        disabled: !sendLinkEnabled,
                      })}
                      onClick={() => sendLinkEnabled && this.sendPersonalLink()}
                      title={
                        <span>
                          <FormattedMessage
                            defaultMessage="Resend personal link"
                            description="user modal"
                          />
                        </span>
                      }
                    />
                  )}
                  {enableOTPTokenReset && (
                    <ModalCell
                      onClick={this.resetOTPToken}
                      title={
                        <span>
                          <FormattedMessage
                            defaultMessage="Reset OTP token"
                            description="user modal"
                          />
                        </span>
                      }
                    />
                  )}
                </ModalSmallDropdown>
              )}
            </ModalHeader>
            <ModalCell
              title={intl.formatMessage({
                defaultMessage: "Active",
                description: "user modal",
              })}
              onClick={() => this.edit({ active: !user.active })}
            >
              <Switch active={user.active}></Switch>
            </ModalCell>

            <ModalSeparator
              title={intl.formatMessage({
                defaultMessage: "User details",
                description: "user modal",
              })}
            />
            <ModalLabel
              title={
                <RedStar>
                  <FormattedMessage
                    defaultMessage="Email"
                    description="user modal"
                  />
                </RedStar>
              }
            >
              <Input
                type="email"
                value={user.email}
                disabled={"id" in user}
                name={"email"}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter email",
                  description: "user modal",
                })}
                onChange={(email) => this.edit({ email })}
              />
            </ModalLabel>
            {emailError && <span className={styles.error}>{emailError}</span>}
            <ModalLabel title={intl.formatMessage(texts.fullName)}>
              <Input
                trim={false}
                value={user.profile.full_name || ""}
                name={"name"}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter full name",
                  description: "user modal",
                })}
                onChange={(full_name) => this.edit({ profile: { full_name } })}
              />
            </ModalLabel>
            <ModalLabel
              title={intl.formatMessage({
                defaultMessage: "Company",
                description: "user modal",
              })}
            >
              <Input
                trim={false}
                value={user.profile.company || ""}
                name={"company"}
                placeholder={intl.formatMessage({
                  defaultMessage: "Enter company",
                  description: "user modal",
                })}
                onChange={(company) => this.edit({ profile: { company } })}
              />
            </ModalLabel>
            {enableDutyPhones && (
              <>
                <ModalSeparator
                  title={intl.formatMessage({
                    defaultMessage: "Personal phone",
                    description: "user modal",
                  })}
                  className={styles.phoneSection}
                />
                <ModalCell
                  title={intl.formatMessage({
                    defaultMessage: "Phone",
                    description: "user modal",
                  })}
                  hoverable={false}
                >
                  <Input
                    mask={phonesMask}
                    value={user?.phone?.phone || ""}
                    onChange={this.onPhoneChange}
                    onClear={() => this.edit({ phone: null })}
                  />
                </ModalCell>
                {phoneError && (
                  <span className={styles.error}>{phoneError}</span>
                )}
                <ModalCell
                  title={intl.formatMessage({
                    defaultMessage: "Active",
                    description: "user modal",
                  })}
                  hoverable={!!this.state.user.phone}
                  onClick={() =>
                    this.state.user.phone &&
                    this.edit({ phone: { active: !user.phone?.active } })
                  }
                >
                  <Switch
                    active={user.phone ? user.phone.active : true}
                    disabled={!this.state.user.phone}
                  />
                </ModalCell>
              </>
            )}
            <ModalLabel
              title={
                <RedStar>
                  <FormattedMessage
                    defaultMessage="Roles"
                    description="user modal"
                  />
                </RedStar>
              }
              className={styles.rolesSection}
            >
              <ModalCombobox
                className={styles.userModal}
                title={intl.formatMessage({
                  defaultMessage: "Start typing role...",
                  description: "user modal",
                })}
                onValueSelect={(v) => this.setRoles(xor(user.roles, [+v]))}
                selectedIds={user.roles.map((v) => v.toString())}
                options={rolesOptions}
              />
              <span className={"error"}>{rolesError}</span>
            </ModalLabel>

            <ModalSpacer />

            <PrimaryButton onClick={() => !loading && this.save()}>
              <FormattedMessage
                defaultMessage="Save"
                description="User modal"
              />
            </PrimaryButton>
          </Modal>
        )}
      </IntlContext.Consumer>
    );
  }
}

export default injectIntl(observer(UserModal));
