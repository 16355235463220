import ReactDOM from "react-dom";
import "./styles/index.scss";
import App from "./components/App";
import { rootStore } from "@stores/RootStore";
import { RootContext } from "@services/react";

ReactDOM.render(
  <RootContext.Provider value={rootStore}>
    <App />
  </RootContext.Provider>,
  document.getElementById("root")
);

window._COMMIT_HASH = process.env.CI_COMMIT_SHA || "none";
