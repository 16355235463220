import { action, makeAutoObservable } from "mobx";
import { confirmModal } from "@services/syncedModal";
import { AssaiaUser } from "@frontend/assaia-ui";
import { IAuthStore } from "./index.types";
import { IRootStore } from "../RootStore/index.types";
import { defineMessage } from "react-intl";
import { TResourceValue } from "@services/types";

const logoutMessage = defineMessage({
  defaultMessage: "Are you sure you want to log out?",
  description: "Logout confirmation message",
});

export default class AuthStore implements IAuthStore {
  private _logoutCallback = () => {};
  user: AssaiaUser | null = null;

  constructor(private root: IRootStore) {
    makeAutoObservable(this, {
      logout: action.bound,
      setUser: action.bound,
      setLogoutCallback: action.bound,
    });
  }

  init(user: AssaiaUser) {
    this.setUser(user);
  }

  setUser(user: AssaiaUser) {
    // Sentry.setUser({
    //   email: user.profile.email,
    //   // @ts-ignore
    //   username: user.profile.profile?.full_name
    // })

    this.user = user;
  }

  setLogoutCallback(cb: () => void) {
    this._logoutCallback = cb;
  }

  async logout(ask = true) {
    const confirmed = !ask || (await confirmModal(logoutMessage));
    if (!confirmed) {
      return;
    }

    // Sentry.setUser(null);

    this._logoutCallback();
  }

  get isSigned() {
    return Boolean(this.user && !this.user.expired);
  }

  get jwtAuthToken() {
    return this.user?.id_token;
  }

  get bearerToken() {
    return this.user?.access_token;
  }

  get jwtAuthHeader() {
    if (!this.jwtAuthToken) {
      return "";
    }
    return "JWT " + this.jwtAuthToken;
  }

  get bearerAuthHeader() {
    if (!this.bearerToken) {
      return "";
    }
    return "Bearer " + this.bearerToken;
  }

  get extractedResources(): TResourceValue[] {
    return (
      this.user?.profile.resources
        ?.map((v) => {
          const result: TResourceValue = {
            id: v,
            roleName: v.split("::")[0] || v,
          };

          return result;
        })
        .filter((v) => v.id) || []
    );
  }
}
