import { makeAutoObservable } from "mobx";
import { cloneDeep, difference, keyBy, union, xor } from "lodash";
import {
  flightStatusFilterValuesDescription,
  getSelectedTypes,
  getStatusValue,
} from "@services/data";
import {
  IncidentConfig,
  TFlightStatusFilterValue,
} from "@models/incidentConfig";
import { IHomeStore } from "../HomeStore/index.types";
import { createLogger } from "@services/createLogger";

const logger = createLogger("FiltersStore");

export class FiltersStore {
  aircraftFilterType: "includes" | "excludes" = "includes";
  airlineFilterType: "includes" | "excludes" = "includes";

  selectedAircraftTypes: string[] = [];

  standFilterType: "includes" | "excludes" = "includes";
  selectedStands: string[] = [];

  inboundFlightStatus: IncidentConfig["inboundFlightStatus"] = null;
  outboundFlightStatus: IncidentConfig["outboundFlightStatus"] = null;
  requiredTurnaroundLength: IncidentConfig["requiredTurnaroundLength"] = null;

  showStandFilters = false;
  showAirlineFilters = false;
  selectedAirlineIds: string[] = [];

  constructor(
    private _home: IHomeStore,
    data: {
      requiredAircraftTypes: string[];
      excludedAircraftTypes: string[];
      requiredStands?: string[];
      excludedStands?: string[];
      requiredAirlineIcaoAndIata?: string[];
      excludedAirlineIcaoAndIata?: string[];
      inboundFlightStatus: FiltersStore["inboundFlightStatus"];
      outboundFlightStatus: FiltersStore["outboundFlightStatus"];
      requiredTurnaroundLength: FiltersStore["requiredTurnaroundLength"];
    },
    options: {
      showStandFilters: boolean;
      showAirlineFilters: boolean;
    },
  ) {
    logger.log(
      "Creating FiltersStore",
      cloneDeep({
        data,
        options,
      }),
    );

    const {
      requiredAircraftTypes,
      excludedAircraftTypes,
      requiredStands = [],
      excludedStands = [],
      inboundFlightStatus,
      outboundFlightStatus,
      requiredTurnaroundLength,
    } = data;
    const aircraftsFilter = getSelectedTypes(
      requiredAircraftTypes,
      excludedAircraftTypes,
    );
    this.aircraftFilterType = aircraftsFilter.filterType;
    this.selectedAircraftTypes = aircraftsFilter.selectedTypes;

    const airlinesFilter = getSelectedTypes(
      data.requiredAirlineIcaoAndIata || [],
      data.excludedAirlineIcaoAndIata || [],
    );

    const iataMap = keyBy(this._home.airlines, (v) => v.iata);
    const icaoMap = keyBy(this._home.airlines, (v) => v.icao);
    const icaoIataMap = { ...iataMap, ...icaoMap };

    this.airlineFilterType = airlinesFilter.filterType;
    this.selectedAirlineIds = airlinesFilter.selectedTypes
      .map((icaoOrIata) => icaoIataMap[icaoOrIata]?.id)
      .filter(Boolean);

    const standsFilter = getSelectedTypes(requiredStands, excludedStands);
    this.standFilterType = standsFilter.filterType;
    this.selectedStands = standsFilter.selectedTypes;

    this.inboundFlightStatus = inboundFlightStatus;
    this.outboundFlightStatus = outboundFlightStatus;
    this.requiredTurnaroundLength = requiredTurnaroundLength;

    this.showStandFilters = options.showStandFilters;
    this.showAirlineFilters = options.showAirlineFilters;

    makeAutoObservable(this, {}, { autoBind: true });
  }

  get aircrafts() {
    return this._home.aircrafts;
  }

  get stands() {
    return this._home.notificationsStore.stands;
  }

  get flightStatus() {
    const { inboundFlightStatus, outboundFlightStatus } = this;
    return getStatusValue({ inboundFlightStatus, outboundFlightStatus });
  }

  get turnaroundLength() {
    return this.requiredTurnaroundLength;
  }

  get aircraftFilters(): {
    requiredAircraftTypes: string[];
    excludedAircraftTypes: string[];
  } {
    const { aircraftFilterType, selectedAircraftTypes } = this;
    if (aircraftFilterType === "includes") {
      return {
        requiredAircraftTypes: [...selectedAircraftTypes],
        excludedAircraftTypes: [],
      };
    } else {
      return {
        requiredAircraftTypes: [],
        excludedAircraftTypes: [...selectedAircraftTypes],
      };
    }
  }

  get standFilters(): {
    requiredStands: string[];
    excludedStands: string[];
  } {
    const { standFilterType, selectedStands } = this;
    if (standFilterType === "includes") {
      return {
        requiredStands: [...selectedStands],
        excludedStands: [],
      };
    } else {
      return {
        requiredStands: [],
        excludedStands: [...selectedStands],
      };
    }
  }

  get idToAirlineMap() {
    const map = keyBy(this._home.airlines, (v) => v.id);
    return map;
  }

  get airlineFilters(): {
    requiredAirlineIcaoAndIata: string[];
    excludedAirlineIcaoAndIata: string[];
  } {
    const { airlineFilterType, selectedAirlineIds } = this;

    const idToAirlineMap = this.idToAirlineMap;
    const icaoIataValues = selectedAirlineIds
      .map((id) => idToAirlineMap[id])
      .filter(Boolean)
      .flatMap((v) => [v.iata, v.icao]);

    logger.log(
      "airlineFilters",
      cloneDeep({
        airlineFilterType,
        icaoIataValues,
      }),
    );

    if (airlineFilterType === "includes") {
      return {
        requiredAirlineIcaoAndIata: icaoIataValues,
        excludedAirlineIcaoAndIata: [],
      };
    } else {
      return {
        requiredAirlineIcaoAndIata: [],
        excludedAirlineIcaoAndIata: icaoIataValues,
      };
    }
  }

  get allAirlines() {
    return this._home.airlines;
  }

  toggleAircraftGroup(aircraftGroup: string) {
    const foundGroup = this._home.aircrafts.find(
      (v) => v.groupName === aircraftGroup,
    );

    if (!foundGroup) {
      return;
    }

    const { groupTypes } = foundGroup;
    const everySelected = groupTypes.every((v) =>
      this.selectedAircraftTypes.includes(v),
    );

    if (everySelected) {
      this.selectedAircraftTypes = difference(
        this.selectedAircraftTypes,
        groupTypes,
      );
    } else {
      this.selectedAircraftTypes = union(
        this.selectedAircraftTypes,
        groupTypes,
      );
    }
  }

  toggleAircraft(v: string) {
    const aircraftExists = this._home.aircrafts.some((group) =>
      group.groupTypes.includes(v),
    );

    if (!aircraftExists) {
      return;
    }

    this.selectedAircraftTypes = xor(this.selectedAircraftTypes, [v]);
  }

  toggleStand(v: string) {
    const standExists = this.stands.includes(v);
    if (!standExists) {
      return;
    }

    this.selectedStands = xor(this.selectedStands, [v]);
  }

  toggleAirline(idToToggle: string) {
    this.selectedAirlineIds = xor(this.selectedAirlineIds, [idToToggle]);
  }

  setAircraftsFilterType(v: FiltersStore["aircraftFilterType"]) {
    this.aircraftFilterType = v;
  }

  setAirlineFilterType(v: FiltersStore["airlineFilterType"]) {
    this.airlineFilterType = v;
  }

  setStandsFilterType(v: FiltersStore["standFilterType"]) {
    this.standFilterType = v;
  }

  setFlightStatus(v: TFlightStatusFilterValue | null) {
    let inboundFlightStatusValue: IncidentConfig["inboundFlightStatus"] = null;
    let outboundFlightStatusValue: IncidentConfig["outboundFlightStatus"] =
      null;

    if (v) {
      ({ inboundFlightStatusValue, outboundFlightStatusValue } =
        flightStatusFilterValuesDescription[v]);
    }

    this.inboundFlightStatus = inboundFlightStatusValue;
    this.outboundFlightStatus = outboundFlightStatusValue;
  }

  setTurnaroundLength(v?: FiltersStore["turnaroundLength"]) {
    this.requiredTurnaroundLength = v || null;
  }
}
