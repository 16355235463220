import { createContext } from "react";
import { IHomeStore } from "@stores/HomeStore";
import { IPtsScheduleStore } from "@stores/PtsScheduleStore";
import { IRootStore } from "@stores/RootStore";
import { IIncidentModalStore } from "@stores/IncidentModalStore";

export const RootContext = createContext<IRootStore>({} as IRootStore);

export const HomeContext = createContext<IHomeStore>({} as IHomeStore);

export const PtsScheduleContext = createContext<IPtsScheduleStore>(
  {} as IPtsScheduleStore,
);

export const IncidentModalStoreContext = createContext<IIncidentModalStore>(
  {} as IIncidentModalStore,
);
