import { makeAutoObservable, runInAction } from "mobx";
import { UsersApi } from "@services/types";
import User, { NewUser } from "../../models/user";
import Role from "../../models/role";
import { exportUsersToCSV } from "@services/export";
import { get, has, keyBy, sortBy, uniqBy } from "lodash";
import { IUsersStore } from "./index.types";
import { IHomeStore } from "../HomeStore/index.types";
import { defineMessages } from "react-intl";

const APPROX_USER_ROW_HEIGHT = 40; // px
const USER_SEARCH_CHAR_THRESHOLD = 1; // string length

const messages = defineMessages({
  activate: {
    defaultMessage:
      "Are you sure to activate the {count, plural, one {user} other {users}}",
    description: "Activate/deactivate user",
  },
  deactivate: {
    defaultMessage:
      "Are you sure to activate the {count, plural, one {user} other {users}}",
    description: "Activate/deactivate user",
  },
  deleteUsers: {
    defaultMessage:
      "Are you sure to delete the {count, plural, one {user} other {users}}",
    description: "Delete user",
  },
  resetPassword: {
    defaultMessage:
      "Are you sure to reset the {count, plural, one {password} other {passwords}}",
    description: "Reset password",
  },
  resetOTPToken: {
    defaultMessage:
      "Are you sure to reset the {count, plural, one {OTP token} other {OTP tokens}}",
    description: "Reset OTP token",
  },
  personalLink: {
    defaultMessage: "Are you sure to send personal link",
    description: "Send personal link",
  },
});

export default class UsersStore implements IUsersStore {
  selectedUsersIds: number[] = [];
  searchQuery = "";
  userModalData: User | NewUser | null = null;
  users: User[] = [];
  roles: Role[] = [];
  ready = false;
  highlightItemId: number | null = null;

  currentPage = 0;
  pageSize = 10;
  limitHasReached = false;
  exportingUsers = false;
  loading = false;

  requestId: number | null = null;

  constructor(
    private home: IHomeStore,
    private api: UsersApi,
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get usersMap() {
    return keyBy(this.users, "id");
  }

  get rolesMap() {
    return keyBy(this.roles, "id");
  }

  get moreUsersLoading() {
    return this.requestId !== null;
  }

  get filteredUsers() {
    let { users } = this;
    const { searchQuery } = this;

    if (searchQuery && searchQuery.length >= 2) {
      const query = searchQuery.toLowerCase().trim();
      users = users.filter((u) => {
        const userNameMatch =
          u.profile.full_name &&
          u.profile.full_name.toLowerCase().includes(query);
        const emailMatch = u.email.toLowerCase().includes(query);

        return userNameMatch || emailMatch;
      });
    }

    return sortBy(users, "email");
  }

  async init(initialPageSize?: number) {
    if (this.ready) {
      return;
    }

    try {
      // Make page size according with window height
      this.pageSize =
        initialPageSize ||
        Math.ceil((window?.innerHeight || 1000) / APPROX_USER_ROW_HEIGHT);
      const p1 = this.api.getUsers({
        page_number: this.currentPage,
        page_size: this.pageSize,
      });
      const p2 = this.api.getRoles();

      const [users, roles] = await Promise.all([p1, p2]);
      runInAction(() => {
        this.users = users;
        this.roles = roles;
      });
    } catch (e) {
      this.home.setUIAlert("Error: something went wrong", "error");
      throw e;
    } finally {
      runInAction(() => {
        this.ready = true;
      });
    }
  }

  /**
   * Opens modal
   */
  createUser() {
    const user: UsersStore["userModalData"] = {
      active: true,
      email: "",
      profile: {
        company: null,
        full_name: null,
        phone: null,
      },
      roles: [],
      phone: null,
      token: "",
    };

    this.userModalData = user;
  }

  /**
   * Opens modal
   */
  editUser(user?: User) {
    this.userModalData = user || null;
  }

  async saveUser(data: User | NewUser) {
    const user = await ("id" in data
      ? this._handlePutUser(data)
      : this._handleCreateUser(data));

    user && this.setHighlightItem(user.id);
  }

  async resetPassword(ids: number[]) {
    await this.home.showConfirmModal(messages.resetPassword, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await Promise.all(
        ids.map((id) => this.api.resetPassword(id)),
      );

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter(
        (v) => v.email?.delivery_status === "FAILED",
      );
      if (failed.length) {
        let message = failed.length === 1 && failed[0].email?.error;
        if (!message) {
          message = `Email has not been sent for some users`;
        }
        this.home.setUIAlert(message, "error");
        return;
      }

      const message = `Reset password link${ids.length > 1 ? "s" : ""} ${
        ids.length > 1 ? "have" : "has"
      } been sent to the user${ids.length > 1 ? "s" : ""}`;
      this.home.setUIAlert(message);
    } catch (e) {
      this.home.setUIAlert("Error: the password has not been reset", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  async resetOTPToken(ids: number[]) {
    await this.home.showConfirmModal(messages.resetOTPToken, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await Promise.all(
        ids.map((id) => this.api.resetOTPToken(id)),
      );

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter(
        (v) => v.email?.delivery_status === "FAILED",
      );
      if (failed.length) {
        let message = failed.length === 1 && failed[0].email?.error;
        if (!message) {
          message = `Email has not been sent for some users`;
        }
        this.home.setUIAlert(message, "error");
        return;
      }

      this.home.setUIAlert(
        `OTP token${ids.length > 1 ? "s" : ""} ${
          ids.length > 1 ? "have" : "has"
        } been reset successfully`,
      );
    } catch (e) {
      this.home.setUIAlert("Error: the OPT token has not been reset", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  async sendPersonalLink(userId: number) {
    await this.home.showConfirmModal(messages.personalLink);

    try {
      this._setLoading(true);

      await this.api.resetToken(userId);

      this.home.setUIAlert("Personal link has been reset");
    } catch (e) {
      this.home.setUIAlert("Error: something wrong", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  async deleteUsers(ids: number[]) {
    await this.home.showConfirmModal(messages.deleteUsers, {
      count: ids.length,
    });

    try {
      this._setLoading(true);

      const result = await this.api.deleteUsers(ids);

      // TODO: Add list with emails for failed delivery status
      const failed = result.filter(
        (v) => v.email?.delivery_status === "FAILED",
      );
      if (failed.length) {
        let message = failed.length === 1 && failed[0].email?.error;
        if (!message) {
          message = `Email has not been sent for some users`;
        }
        this.home.setUIAlert(message, "error");
        return;
      }

      runInAction(() => {
        this.users = this.users.filter((v) => !ids.includes(v.id));
      });

      this.home.setUIAlert(
        "The changes have been applied. The system will be updated within 30 minutes.",
      );
    } catch (e) {
      // Can throw an error
      this.home.handlePhoneError(e, false);

      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  async toggleUsersActive(ids: number[], active: boolean) {
    await this.home.showConfirmModal(
      active ? messages.activate : messages.deactivate,
      { count: ids.length },
    );

    const filteredUsers = this.users
      .filter(({ id }) => ids.includes(id))
      .map((n) => ({ ...n, active }));

    try {
      this._setLoading(true);

      const data = await this.api.toggleUsersActive(filteredUsers);

      data.map(({ user }) => this._updateUser(user));

      const msg = `User${ids.length > 1 ? "s" : ""} ${
        ids.length > 1 ? "have" : "has"
      } been ${active ? "" : "de"}activated`;
      this.home.setUIAlert(msg);
    } catch (e) {
      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  setSelectedUsers(ids: number[]) {
    this.selectedUsersIds = ids;
  }

  setUserQuery(query: string) {
    this.searchQuery = query;
    this.selectedUsersIds = [];
  }

  async searchUsers(oldQuery: string, newQuery: string) {
    oldQuery = oldQuery.toLowerCase().trim();
    newQuery = newQuery.toLowerCase().trim();
    if (!oldQuery && newQuery.length <= USER_SEARCH_CHAR_THRESHOLD) {
      return;
    }

    this.limitHasReached = false;
    this.users = [];
    return this._handleLoadMoreUsers(0, newQuery);
  }

  async exportUsers() {
    try {
      this.exportingUsers = true;
      const allUsers = await this.api.getUsers();
      exportUsersToCSV(allUsers, this.roles);
    } catch {
      this.home.setUIAlert("Export users has failed", "error");
    } finally {
      runInAction(() => (this.exportingUsers = false));
    }
  }

  setHighlightItem(id: UsersStore["highlightItemId"]) {
    this.highlightItemId = id;
  }

  async loadMoreUsers() {
    if (this.moreUsersLoading) {
      return;
    }

    return this._handleLoadMoreUsers(this.currentPage + 1);
  }

  async nextPage() {
    if (!this.limitHasReached) {
      return this.loadMoreUsers();
    }
  }

  private async _handleLoadMoreUsers(targetPage: number, query = "") {
    const { pageSize } = this;

    const requestId = Date.now();
    this.requestId = requestId;

    const withoutPagination = query.length > USER_SEARCH_CHAR_THRESHOLD;
    try {
      const newUsers = await this.api.getUsers(
        withoutPagination
          ? {
              full_name: query,
              email: query,
            }
          : {
              page_number: targetPage,
              page_size: pageSize,
            },
      );

      if (this.requestId !== requestId) {
        return;
      }

      runInAction(() => {
        if (withoutPagination) {
          this.users = sortBy(newUsers, (v) => v.email);
          this.limitHasReached = true;
          return;
        }

        this.users = uniqBy([...this.users, ...newUsers], (v) => v.id);
        this.currentPage = targetPage;
        this.limitHasReached = newUsers.length < pageSize;
      });
    } finally {
      runInAction(() => {
        if (this.requestId === requestId) {
          this.requestId = null;
        }
      });
    }
  }

  private async _handleCreateUser(data: NewUser) {
    try {
      this._setLoading(true);

      const { user, email } = await this.api.createUser(data);

      // If an email hasn't been sent, a new user WILL NOT be created on backend
      if (email?.delivery_status === "FAILED") {
        const message = email.error
          ? email.error
          : "Error: changes have not been applied";
        this.home.setUIAlert(message, "error");
        return;
      }

      this._updateUser(user);

      this.home.setUIAlert("The user has been added");

      return user;
    } catch (e) {
      let errorText = "Error: changes have not been applied";
      const axiosErrorMessagePath = "response.data.error";
      if (has(e, axiosErrorMessagePath)) {
        errorText = get(e, axiosErrorMessagePath) || "";
      }
      this.home.setUIAlert(errorText, "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  private async _handlePutUser(data: User) {
    try {
      this._setLoading(true);
      const { user, email } = await this.api.updateUser(data);

      this._updateUser(user);

      // If an email hasn't been sent, a user WILL BE updated on backend
      if (email?.delivery_status === "FAILED") {
        const message = email.error
          ? email.error
          : "Error: an email has not been sent";
        this.home.setUIAlert(message, "error");
        return;
      }

      this.home.setUIAlert("Changes have been applied");

      return user;
    } catch (e) {
      // Can throw an error
      this.home.handlePhoneError(e);

      this.home.setUIAlert("Error: changes have not been applied", "error");
      throw e;
    } finally {
      this._setLoading(false);
    }
  }

  private _updateUser(data: User) {
    const idx = this.users.findIndex((n) => n.id === data.id);
    if (idx < 0) {
      this.users.push(data);
      return;
    }

    this.users[idx] = data;
  }

  private _setLoading(v: boolean) {
    this.loading = v;
  }
}
