import React, { useContext, useEffect } from "react";
import styles from "./style.module.scss";
import Spinner from "../Common/Spinner";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import PtsGroup from "./PtsGroup";
import Content from "../Common/Content";

const PtsListPage = () => {
  const { ptsStore, ptsGroups } = useContext(HomeContext);
  const { initPtsList, ready } = ptsStore;

  useEffect(() => {
    initPtsList();
  }, []);

  if (!ready) {
    return <Spinner />;
  }

  return (
    <Content>
      <div className={styles.ptsPage}>
        {ptsGroups.map((group) => (
          <PtsGroup group={group} key={group.id} />
        ))}
      </div>
    </Content>
  );
};

export default observer(PtsListPage);
