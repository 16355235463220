import { observer } from "mobx-react";
import styles from "./style.module.scss";
import { PtsEventScheduleOptions } from "@models/pts";
import classNames from "classnames";
import { getPtsOperationChartPoints } from "@services/ptsOperationChart/ptsOperationChart";
import { ReactComponent as SmallArrowIcon } from "@assets/small-arrow.svg";

type Props = {
  data: PtsEventScheduleOptions;
  isStart: boolean;
  calculatedPoints: ReturnType<typeof getPtsOperationChartPoints>;
  className?: string;
};

const PtsOperationChart: React.FC<Props> = ({
  data,
  className,
  isStart,
  calculatedPoints: result,
}) => {
  const { values: res, absValues } = result;

  const idealTimeAbs = isNaN(res.idealTime) ? 0.5 : res.idealTime;

  const redStartAbs = isNaN(res.redStart) ? 0 : res.redStart;
  const orangeStartAbs = isNaN(res.orangeStart) ? 0 : res.orangeStart;

  const orangeEndAbs = isNaN(res.orangeEnd) ? 1 : res.orangeEnd;
  const redEndAbs = isNaN(res.redEnd) ? 1 : res.redEnd;

  const orangeWidth = orangeEndAbs - orangeStartAbs;
  const redWidth = redEndAbs - redStartAbs;

  let leftPaddingColor = styles.grey;
  if (data.orangeInterval.start !== null) {
    leftPaddingColor = styles.orange;
  }
  if (data.redInterval.start !== null) {
    leftPaddingColor = styles.red;
  }

  let rightPaddingColor = styles.grey;
  if (data.orangeInterval.end !== null) {
    rightPaddingColor = styles.orange;
  }
  if (data.redInterval.end !== null) {
    rightPaddingColor = styles.red;
  }

  const arrow = (
    <div
      className={classNames(styles.arrowIcon, {
        [styles.reverted]: !isStart,
      })}
    >
      <SmallArrowIcon />
    </div>
  );

  return (
    <>
      <div className={classNames(styles.chartWrapper, className)}>
        <div
          className={classNames(styles.sidePadding, leftPaddingColor)}
          style={{ left: 0 }}
        />
        <div
          className={classNames(styles.sidePadding, rightPaddingColor)}
          style={{ right: 0 }}
        />
        <div className={styles.chart}>
          <div
            className={styles.red}
            style={{
              left: `${(redStartAbs || 0) * 100}%`,
              width: `${(redWidth || 0) * 100}%`,
            }}
          >
            <span className={styles.leftValue}>{data.redInterval.start}</span>
            <span className={styles.rightValue}>{data.redInterval.end}</span>
          </div>
          <div
            className={styles.orange}
            style={{
              left: `${(orangeStartAbs || 0) * 100}%`,
              width: `${(orangeWidth || 0) * 100}%`,
            }}
          >
            <span className={styles.leftValue}>
              {data.orangeInterval.start}
            </span>
            <span className={styles.rightValue}>{data.orangeInterval.end}</span>
          </div>
          <div
            className={styles.ideal}
            style={{
              left: `${(idealTimeAbs || 0) * 100}%`,
            }}
          >
            <span className={styles.idealValue}>{data.idealTime}</span>
          </div>
          <div
            className={styles.detection}
            style={
              isStart
                ? {
                    left: `${(idealTimeAbs || 0) * 100}%`,
                  }
                : {
                    right: `${Math.abs(1 - (idealTimeAbs || 0)) * 100}%`,
                  }
            }
          >
            {arrow}
            {arrow}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(PtsOperationChart);
