import React, { useContext } from "react";
import s from "./style.module.scss";
import { HomeContext } from "@services/react";
import { observer } from "mobx-react";
import {
  ModalHeader,
  PrimaryButton,
  Modal,
  ModalLabel,
} from "@frontend/assaia-ui";
import { FormattedMessage, useIntl } from "react-intl";

const UIAlertModal = () => {
  const { uiAlert: notification, setUIAlert } = useContext(HomeContext);
  const intl = useIntl();

  if (!notification) {
    return null;
  }

  const onClose = () => setUIAlert();
  const isError = notification.type === "error";
  return (
    <Modal className={s.uiAlert} onClose={onClose}>
      <ModalHeader
        title={
          isError
            ? intl.formatMessage({
                defaultMessage: "Error",
                description: "Error alert title",
              })
            : ""
        }
        onClose={isError ? onClose : undefined}
      />
      <ModalLabel className={s.text} title={notification.text} />
      <PrimaryButton onClick={onClose}>
        <FormattedMessage
          defaultMessage="OK"
          description="Alert ok button text"
        />
      </PrimaryButton>
    </Modal>
  );
};

export default observer(UIAlertModal);
