import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { find } from "lodash";
import styles from "./style.module.scss";
import GroupHeader from "@components/Common/GroupHeader";
import { Table } from "@frontend/assaia-ui";
import { UnifiedIncidentConfig } from "@models/incidentConfig";
import { HomeContext } from "@services/react";
import { useIntl } from "react-intl";
import { getColumns } from "@components/NotificationsPage/NotificationsGroup/helpers";
import { TResourceValue } from "@services/types";

type Props = {
  group: TResourceValue;
};

const NotificationsGroup = ({ group }: Props) => {
  const {
    notifications: allNotifications,
    prepareNotificationsByGroup,
    searchQuery,
    createNotification,
    deleteNotifications,
    editNotification,
    toggleNotificationsActive,
    highlightItemId,
    setHighlightItem,
  } = useContext(HomeContext).notificationsStore;
  const intl = useIntl();

  const notifications = prepareNotificationsByGroup(group, intl);

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    setSelectedNotificationsIds([]);
  }, [searchQuery]);

  useEffect(() => {
    const tmpIds = selectedNotificationsIds.filter((id) =>
      find(notifications, { id }),
    );
    if (tmpIds.length !== selectedNotificationsIds.length) {
      setSelectedNotificationsIds(tmpIds);
    }
  }, [selectedNotificationsIds, allNotifications]);

  const selectedNotifications = notifications.filter((n) =>
    selectedNotificationsIds.includes(n.id),
  );

  return (
    <div className={styles.notificationsGroup}>
      <GroupHeader
        singularLabel={intl.formatMessage({
          defaultMessage: "notification",
          description: "notifications group header",
        })}
        pluralLabel={intl.formatMessage({
          defaultMessage: "notifications",
          description: "notifications group header",
        })}
        group={group.roleName}
        activeItems={selectedNotifications
          .filter(({ active }) => active)
          .map(({ id }) => id)}
        selectedItems={selectedNotifications.map(({ id }) => id)}
        onAddItem={() => createNotification(group)}
        onDelete={() => deleteNotifications(selectedNotificationsIds)}
        onToggleActive={(val) =>
          toggleNotificationsActive(selectedNotificationsIds, val)
        }
      />

      <Table
        columns={getColumns(intl)}
        getRowKey={(n: UnifiedIncidentConfig) => n.id}
        items={notifications.filter((n) => n.group.id === group.id)}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={(n: UnifiedIncidentConfig) => editNotification(n)}
        // TODO: It seems to be broken, no css modules
        getRowClass={() => styles.notificationRow}
        highlightItemId={highlightItemId}
        onHighlightEnd={() => setHighlightItem(null)}
      />
    </div>
  );
};

export default observer(NotificationsGroup);
