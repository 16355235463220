import React, { useContext, useEffect, useState } from "react";
import Spinner from "../../Common/Spinner";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { useHistory } from "react-router-dom";
import GroupHeader from "../../Common/GroupHeader";
import { Pts } from "@models/pts";
import { NEW_PTS } from "@services/constants";
import classNames from "classnames";
import ActiveCell from "../../Common/ActiveCell";
import { Table } from "@frontend/assaia-ui";
import { URLS } from "@services/router";
import { IntlShape, useIntl } from "react-intl";
import { TURN_LENGTH_LABELS } from "@i18n/messages";
import { TResourceValue } from "@services/types";

type Props = {
  group: TResourceValue;
};

const getColumns = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      defaultMessage: "Airline",
      description: "Pts table columns",
    }),
    width: "25%",
    render: ({ airline: airline }: Pts) => airline.roleName,
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Description",
      description: "Pts table columns",
    }),
    width: "35%",
    render: (v: Pts) => v.description,
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Length",
      description: "Pts table columns",
    }),
    width: "35%",
    render: (v: Pts) =>
      v.filters.requiredTurnaroundLength
        ? intl.formatMessage(
            TURN_LENGTH_LABELS[v.filters.requiredTurnaroundLength],
          )
        : "",
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Active",
      description: "Pts table columns",
    }),
    width: "5%",
    getCellClass: (v: Pts) => classNames("bold", { green: v.active }),
    render: (v: Pts) => <ActiveCell active={v.active} />,
  },
];

const PtsGroup = ({ group }: Props) => {
  const history = useHistory();
  const { ptsStore } = useContext(HomeContext);
  const {
    ptsList,
    initPtsList,
    togglePtsActive,
    ready,
    setSelectedPtsId,
    deletePtsList,
    copyPtsList,
  } = ptsStore;
  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);
  const intl = useIntl();

  const filteredPtsList = ptsList.filter((v) => v.airline.id === group.id);

  useEffect(() => {
    void initPtsList();
  }, []);

  const openPts = (v: Pts) => {
    history.push(`${URLS.PTS}/${v.id}`);
  };

  const onNewPts = () => {
    setSelectedPtsId(NEW_PTS, group);
  };

  if (!ready) {
    return <Spinner />;
  }

  return (
    <div>
      <GroupHeader
        singularLabel="PTS"
        pluralLabel="PTS"
        group={group.roleName}
        activeItems={filteredPtsList
          .filter(({ active }) => active)
          .map(({ id }) => id)}
        selectedItems={selectedNotificationsIds}
        onAddItem={onNewPts}
        onDelete={(ids) => deletePtsList(ids)}
        onCopy={(ids) => copyPtsList(ids)}
        onToggleActive={(val) => togglePtsActive(selectedNotificationsIds, val)}
      />

      <Table
        columns={getColumns(intl)}
        getRowKey={(v: Pts) => v.id}
        items={filteredPtsList}
        selectedItems={selectedNotificationsIds}
        onSelect={(ids) => setSelectedNotificationsIds(ids)}
        onRowClick={openPts}
      />
    </div>
  );
};

export default observer(PtsGroup);
