import { createBrowserHistory } from "history";

const backupPathKey = "prevPath";

export const backupUrlPath = () => {
  localStorage.setItem(backupPathKey, window.location.pathname);
};

export const getUrlPathBackup = () => localStorage.getItem(backupPathKey);

export const browserHistory = createBrowserHistory();

export const URLS = {
  USERS: "/users",
  NOTIFICATIONS: "/notifications",
  DUTY_PHONES: "/duty-phones",
  PTS: "/pts",
} as const;
