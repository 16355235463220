import { isFunction } from "lodash";
import { readFromLocalStorage } from "./localStorage";

const LOGGER_ENABLED_STORAGE_KEY = "LOGGER_ENABLED_STORAGE_KEY";

export const createLogger = (
  label: string,
  checkLoggerEnabled?: () => boolean,
) => {
  const checkerToUse = isFunction(checkLoggerEnabled)
    ? checkLoggerEnabled
    : () => readFromLocalStorage(LOGGER_ENABLED_STORAGE_KEY, false).value;

  return {
    log: (...args: Parameters<typeof console.log>) => {
      const newArgs = [`[${label}]`, ...args];
      if (checkerToUse()) {
        console.log(...newArgs);
      }
    },
  };
};
