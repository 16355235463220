import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";

import PtsMainModalScreen from "./PtsMainModalScreen";
import ModalFilterScreen from "../Common/ModalFilterScreen";
import { FiltersStore } from "@stores/FiltersStore";
import { NewPts, Pts } from "@models/pts";
import { Modal } from "@frontend/assaia-ui";

type Props = {
  ptsData: NewPts | Pts;
};

const PtsMainModal: React.FC<Props> = ({ ptsData }) => {
  const homeStore = useContext(HomeContext);
  const { ptsMainModalScreen, closeModal, setPtsMainModalScreen } =
    homeStore.ptsStore;

  // TODO: Create separate store to manage PTS modals
  const filtersStore = useMemo(() => {
    return new FiltersStore(homeStore, ptsData.filters, {
      showStandFilters: false,
      showAirlineFilters: false,
    });
  }, [ptsData]);

  return (
    <Modal onClose={closeModal}>
      {ptsMainModalScreen === "main" && (
        <PtsMainModalScreen filtersStore={filtersStore} />
      )}
      {ptsMainModalScreen === "filters" && (
        <ModalFilterScreen
          onClose={closeModal}
          onBack={() => setPtsMainModalScreen("main")}
          filtersStore={filtersStore}
        />
      )}
    </Modal>
  );
};

export default observer(PtsMainModal);
