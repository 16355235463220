import {
  defineMessages,
  FormattedMessage,
  IntlFormatters,
  IntlShape,
} from "react-intl";
import { IncidentConfig, UnifiedIncidentConfig } from "@models/incidentConfig";
import {
  getSelectedTypes,
  getStatusValue,
  getTimerLabelData,
  getTriggerLabelData,
} from "@services/data";
import TableColumnContent from "@components/NotificationsPage/TableColumnContent";
import React from "react";
import { Popup } from "@frontend/assaia-ui";
import styles from "@components/NotificationsPage/NotificationsGroup/style.module.scss";
import classNames from "classnames";
import SeverityLevel from "@components/Common/SeverityLevel";
import ActiveCell from "@components/Common/ActiveCell";
import { FLIGHT_STATUS_LABELS } from "@i18n/messages";

const MESSAGES = defineMessages({
  INCLUDES_AIRCRAFTS: {
    defaultMessage: "Included aircraft types",
    description: "Notifications table columns",
  },
  EXCLUDES_AIRCRAFTS: {
    defaultMessage: "Excluded aircraft types",
    description: "Notifications table columns",
  },
  INCLUDES_STANDS: {
    defaultMessage: "Included stands",
    description: "Notifications table columns",
  },
  EXCLUDES_STANDS: {
    defaultMessage: "Excluded stands",
    description: "Notifications table columns",
  },
  TURNAROUND_LENGTH: {
    defaultMessage: "Turnaround length",
    description: "Notifications table columns",
  },
  IN_OUT: {
    defaultMessage: "IN/OUT",
    description: "Notifications table columns",
  },
  INCLUDES_AIRLINES: {
    defaultMessage: "Included airlines",
    description: "Notifications table columns",
  },
  EXCLUDES_AIRLINES: {
    defaultMessage: "Excluded airlines",
    description: "Notifications table columns",
  },
});

// TODO refactor, seems overcomplicated
export const getFilterShortInfo = (
  config: IncidentConfig,
  formatMessage: IntlFormatters["formatMessage"],
): [string, string | number | null][] => {
  const {
    requiredAircraftTypes,
    excludedAircraftTypes,
    requiredStands,
    excludedStands,
    requiredAirlineIcaoAndIata,
    excludedAirlineIcaoAndIata,
  } = config;
  const aircraftsFilter = getSelectedTypes(
    requiredAircraftTypes,
    excludedAircraftTypes,
  );
  const standsFilter = getSelectedTypes(requiredStands, excludedStands);
  const statusValue = getStatusValue({
    inboundFlightStatus: config.inboundFlightStatus,
    outboundFlightStatus: config.outboundFlightStatus,
  });

  const airlineFilter = getSelectedTypes(
    requiredAirlineIcaoAndIata,
    excludedAirlineIcaoAndIata,
  );

  return [
    [
      formatMessage(
        aircraftsFilter.filterType === "includes"
          ? MESSAGES.INCLUDES_AIRCRAFTS
          : MESSAGES.EXCLUDES_AIRCRAFTS,
      ),
      aircraftsFilter.selectedTypes.length,
    ],
    [
      formatMessage(
        standsFilter.filterType === "includes"
          ? MESSAGES.INCLUDES_STANDS
          : MESSAGES.EXCLUDES_STANDS,
      ),
      standsFilter.selectedTypes.length,
    ],
    [
      formatMessage(MESSAGES.TURNAROUND_LENGTH),
      config.requiredTurnaroundLength,
    ],
    [
      formatMessage(MESSAGES.IN_OUT),
      statusValue ? formatMessage(FLIGHT_STATUS_LABELS[statusValue]) : "",
    ],
    [
      formatMessage(
        airlineFilter.filterType === "includes"
          ? MESSAGES.INCLUDES_AIRLINES
          : MESSAGES.EXCLUDES_AIRLINES,
      ),
      Math.floor(airlineFilter.selectedTypes.length / 2),
    ],
  ];
};

export const getColumns = (intl: IntlShape) => [
  {
    title: intl.formatMessage({
      defaultMessage: "Trigger",
      description: "Notifications table columns",
    }),
    width: "22%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTriggerLabelData(n, intl);
      return (
        <TableColumnContent
          subtitle={items.map((v) => (
            <span key={v}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Start monitoring",
      description: "Notifications table columns",
    }),
    width: "22%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.startRangeBoundary);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Firing point",
      description: "Notifications table columns",
    }),
    width: "14%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.firingPointInTime);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Stop monitoring",
      description: "Notifications table columns",
    }),
    width: "14%",
    render: (n: UnifiedIncidentConfig) => {
      const { label, items } = getTimerLabelData(n.data.endRangeBoundary);

      return (
        <TableColumnContent
          subtitle={items.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        >
          {label}
        </TableColumnContent>
      );
    },
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Filters",
      description: "Notifications table columns",
    }),
    width: "7%",
    render: (n: UnifiedIncidentConfig) => {
      const filteredData = getFilterShortInfo(n, intl.formatMessage).filter(
        ([, col2]) => col2,
      );
      const tooltipContent = filteredData.length ? (
        filteredData.map(([col1, col2]) => {
          return (
            <React.Fragment key={`filter-item-${col1.toString()}`}>
              <span>{col1}</span>
              <span>{col2}</span>
            </React.Fragment>
          );
        })
      ) : (
        <FormattedMessage
          defaultMessage="No filters"
          description="Notifications table columns"
        />
      );

      return (
        <Popup
          portalQuery="#root"
          className={styles.tooltipWrapper}
          popupHorizontalAlign={"center"}
          popupVerticalAlign={"top"}
          clickable={false}
          triggerOnHover={true}
          popupContent={
            <div
              className={classNames(styles.tooltip, {
                [styles.noFilters]: filteredData.length === 0,
              })}
            >
              {tooltipContent}
            </div>
          }
        >
          <i className={classNames("fas fa-info-circle")}></i>
        </Popup>
      );
    },
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Severity",
      description: "Notifications table columns",
    }),
    width: "7%",
    render: (n: UnifiedIncidentConfig) => (
      <span className={styles.severity}>
        <SeverityLevel severity={n.severity} />
      </span>
    ),
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Custom text",
      description: "Notifications table columns",
    }),
    width: "7%",
    getCellClass: (n: UnifiedIncidentConfig) =>
      classNames("bold", { green: n.customText }),
    render: (n: UnifiedIncidentConfig) => (
      <ActiveCell active={!!n.customText} title={n.customText || ""} />
    ),
  },
  {
    title: intl.formatMessage({
      defaultMessage: "Active",
      description: "Notifications table columns",
    }),
    width: "7%",
    getCellClass: (n: UnifiedIncidentConfig) =>
      classNames("bold", { green: n.active }),
    render: (n: UnifiedIncidentConfig) => <ActiveCell active={n.active} />,
  },
];
