import React from "react";
import "./style.scss";
import { ReactComponent as LogoSvg } from "./logo.svg";
import { observer } from "mobx-react";
import classNames from "classnames";

const Logo = ({ sm, className }: { sm?: boolean; className?: string }) => {
  return (
    <LogoSvg
      className={classNames("logo", className)}
      viewBox={`0 0 ${sm ? 90 : 407} 84`}
    />
  );
};

export default observer(Logo);
