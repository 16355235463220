import { captureException, captureMessage, withScope } from "@sentry/browser";
import { isDev } from "@services/constants";

// TODO: Improve types
export function reportError(message: string, error?: Error): void;
export function reportError(error: Error): void;
export function reportError(message: string | Error, error?: Error): void {
  const shouldLogToConsole = isDev;

  if (shouldLogToConsole) {
    if (error) {
      console.error(message, error);
    } else if (message instanceof Error) {
      console.error(message);
    } else {
      console.warn(message);
    }
  }

  if (error) {
    captureException(error);
  } else if (message instanceof Error) {
    captureException(message);
  } else {
    captureMessage(message);
  }
}

export function reportErrorWithTag(
  error: Error,
  [tagName, tagValue]: [string, any],
) {
  withScope(function (scope) {
    scope.setTag(tagName, tagValue);
    captureException(error);
  });
}

export function reportMessage(msg: string) {
  captureMessage(msg);
}
