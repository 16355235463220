import { useMemo } from "react";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import { Pts, PtsSchedule } from "@models/pts";
import PtsOperationOptionsScreen from "./PtsOperationOptionsScreen";
import { PtsScheduleStore } from "@stores/PtsScheduleStore";
import PtsOperationMainScreen from "./PtsOperationMainScreen";
import { PtsScheduleContext } from "@services/react";
import { IPtsStore } from "@stores/PtsStore";
import { Modal } from "@frontend/assaia-ui";

type Props = {
  pts: Pts;
  schedule: PtsSchedule;
  ptsStore: IPtsStore;
  onClose: () => void;
};

const PtsOperationModal: React.FC<Props> = ({
  pts,
  schedule,
  ptsStore,
  onClose,
}) => {
  const ptsScheduleStore = useMemo(
    () => new PtsScheduleStore(ptsStore, pts, schedule),
    [],
  );
  const { operationScreen, setScreen } = ptsScheduleStore;

  return (
    <PtsScheduleContext.Provider value={ptsScheduleStore}>
      <Modal onClose={onClose} className={styles.ptsOperationModal}>
        {operationScreen === "main" && (
          <PtsOperationMainScreen onClose={onClose} />
        )}

        {operationScreen === "start" && (
          <PtsOperationOptionsScreen
            dataKey={"start"}
            onBack={() => setScreen("main")}
          />
        )}

        {operationScreen === "end" && (
          <PtsOperationOptionsScreen
            dataKey={"end"}
            onBack={() => setScreen("main")}
          />
        )}
      </Modal>
    </PtsScheduleContext.Provider>
  );
};

export default observer(PtsOperationModal);
