import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./style.module.scss";
import { observer } from "mobx-react";
import { HomeContext } from "@services/react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { useHistory, useParams } from "react-router-dom";
import GroupHeader from "../Common/GroupHeader";
import { PtsSchedule } from "@models/pts";
import Spinner from "../Common/Spinner";
import classNames from "classnames";
import PtsOperationModal from "../PtsOperationModal";
import { getStatusValue } from "@services/data";
import { exportPtsScheduleToCSV } from "@services/export";
import ExportButton from "../Common/ExportButton";
import Content from "../Common/Content";
import { TableColumn, Table } from "@frontend/assaia-ui";
import { getReferencePointTitle } from "@services/ptsUtils";
import { PtsScheduleStore } from "@stores/PtsScheduleStore";
import { getConfig } from "@di";
import { URLS } from "@services/router";
import { TURN_LENGTH_LABELS, FLIGHT_STATUS_LABELS } from "@i18n/messages";
import { extractLocalized } from "@i18n";

const getIntervalColumn = (key: "start" | "end") => {
  return {
    title: `${key} window`,
    render: (v: PtsSchedule) => {
      const { referencePoint, idealTime, orangeInterval, redInterval } = v[key];
      const parseInterval = (v: number | null) =>
        v !== null ? `${v / 60}m` : "-";

      const items = [
        redInterval.start,
        orangeInterval.start,
        idealTime,
        orangeInterval.end,
        redInterval.end,
      ].map(parseInterval);

      return (
        <div className={styles.columnContent}>
          <span className={styles.title}>
            <FormattedMessage
              defaultMessage="{title}: {time}m"
              description="pts columns"
              values={{
                title: getReferencePointTitle(referencePoint),
                time: idealTime / 60,
              }}
            />
          </span>
          <span className={styles.subtitle}>
            {items.map((v, i) => (
              <span key={i}>{v}</span>
            ))}
          </span>
        </div>
      );
    },
  };
};

const getColumns = (intl: IntlShape) => {
  const { ptsLabels, enablePtsDirection } = getConfig();

  const columns: TableColumn[] = [
    {
      title: intl.formatMessage({
        defaultMessage: "Operation",
        description: "pts columns",
      }),
      render: (v: PtsSchedule) => extractLocalized(ptsLabels[v.id], v.id),
    },
    getIntervalColumn("start"),
    getIntervalColumn("end"),
    {
      title: intl.formatMessage({
        defaultMessage: "Continuous",
        description: "pts columns",
      }),
      width: 1,
      getCellClass: (v: PtsSchedule) =>
        classNames("bold", { green: v.uninterruptible }),
      render: (v: PtsSchedule) => (v.uninterruptible ? "Yes" : "No"),
    },
  ];

  if (enablePtsDirection) {
    columns.splice(1, 0, {
      title: intl.formatMessage({
        defaultMessage: "Direction",
        description: "pts columns",
      }),
      render: (v: PtsSchedule) => v.direction,
    });
  }

  return columns;
};

const PtsDetailsPage = () => {
  const history = useHistory();
  const { ptsId } = useParams<{ ptsId?: string }>();
  const { ptsStore } = useContext(HomeContext);
  const {
    initPtsList,
    ready,
    setSelectedPtsId,
    highlightItemId,
    setHighlightItem,
    onRemoveOperations,
    getPtsById,
  } = ptsStore;
  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), []);

  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
    string[]
  >([]);

  const [selectedPtsSchedule, setSelectedPtsSchedule] =
    useState<PtsSchedule | null>(null);

  const pts = getPtsById(ptsId);

  useEffect(() => {
    initPtsList();

    return () => setSelectedPtsId(null);
  }, []);

  useEffect(() => {
    if (ready && !pts) {
      history.push(URLS.PTS);
    }
  }, [pts]);

  if (!ready) {
    return <Spinner />;
  }

  if (!pts) {
    return null;
  }

  const newPtsSchedule = PtsScheduleStore.createNewPtsSchedule(pts);
  const { inboundFlightStatus, outboundFlightStatus } = pts.filters;
  const flightStatusTitle =
    !inboundFlightStatus && !outboundFlightStatus
      ? "-"
      : (() => {
          const value = getStatusValue({
            inboundFlightStatus,
            outboundFlightStatus,
          });

          return value ? intl.formatMessage(FLIGHT_STATUS_LABELS[value]) : "-";
        })();

  return (
    <Content>
      <section className={styles.ptsPage}>
        <div className={styles.header}>
          <a onClick={() => history.push(URLS.PTS)} className={styles.backBtn}>
            <i className={"fas fa-arrow-left"} />
          </a>
          <h1>
            <FormattedMessage defaultMessage="Details" description="pts page" />
          </h1>
          <ExportButton onClick={() => exportPtsScheduleToCSV(pts.schedules)} />
        </div>
        <div
          className={styles.details}
          onClick={() => setSelectedPtsId(pts.id)}
        >
          <div className={styles.info}>
            <span>
              <FormattedMessage
                defaultMessage="Airline"
                description="pts page"
              />
            </span>
            <span>{pts.airline.roleName}</span>
            <span>
              <FormattedMessage
                defaultMessage="Length"
                description="pts page"
              />
            </span>
            <span>
              {pts.filters.requiredTurnaroundLength ? (
                intl.formatMessage(
                  TURN_LENGTH_LABELS[pts.filters.requiredTurnaroundLength],
                )
              ) : (
                <FormattedMessage
                  defaultMessage="-"
                  description="[ignore] pts page"
                />
              )}
            </span>
            <span>
              <FormattedMessage
                defaultMessage="Active"
                description="pts page"
              />
            </span>
            <span>
              {pts.active ? (
                <FormattedMessage defaultMessage="Yes" description="pts page" />
              ) : (
                <FormattedMessage defaultMessage="No" description="pts page" />
              )}
            </span>
            <span>
              <FormattedMessage
                defaultMessage="Flight status"
                description="pts page"
              />
            </span>
            <span>
              {flightStatusTitle ? (
                flightStatusTitle
              ) : (
                <FormattedMessage
                  defaultMessage="-"
                  description="[ignore] pts page"
                />
              )}
            </span>
          </div>
          <div className={styles.info}>
            <span>
              <FormattedMessage
                defaultMessage="Description"
                description="pts page"
              />
            </span>
            <span>
              {pts.description || (
                <FormattedMessage
                  defaultMessage="-"
                  description="[ignore] pts page"
                />
              )}
            </span>
            <span>
              <FormattedMessage
                defaultMessage="Included aircrafts"
                description="pts page"
              />
            </span>
            <span>
              {pts.filters.requiredAircraftTypes.length ? (
                pts.filters.requiredAircraftTypes.join(", ")
              ) : (
                <FormattedMessage
                  defaultMessage="-"
                  description="[ignore] pts page"
                />
              )}
            </span>
            <span>
              <FormattedMessage
                defaultMessage="Excluded aircrafts"
                description="pts page"
              />
            </span>
            <span>
              {pts.filters.excludedAircraftTypes.length ? (
                pts.filters.excludedAircraftTypes.join(", ")
              ) : (
                <FormattedMessage
                  defaultMessage="-"
                  description="[ignore] pts page"
                />
              )}
            </span>
          </div>
        </div>

        <GroupHeader
          singularLabel={intl.formatMessage({
            defaultMessage: "operation",
            description: "pts page",
          })}
          pluralLabel={intl.formatMessage({
            defaultMessage: "operations",
            description: "pts page",
          })}
          group={intl.formatMessage({
            defaultMessage: "Operations",
            description: "pts page",
          })}
          activeItems={[]}
          selectedItems={selectedNotificationsIds}
          onAddItem={() => setSelectedPtsSchedule(newPtsSchedule)}
          onDelete={(ids) => onRemoveOperations(pts.id, ids)}
        />

        <Table
          columns={columns}
          getRowKey={(v: PtsSchedule) => v.id}
          items={pts.schedules}
          selectedItems={selectedNotificationsIds}
          onSelect={(ids) => setSelectedNotificationsIds(ids)}
          onRowClick={(v: PtsSchedule) => setSelectedPtsSchedule(v)}
          getRowClass={() => ""}
          highlightItemId={highlightItemId}
          onHighlightEnd={() => setHighlightItem(null)}
        />

        {selectedPtsSchedule && (
          <PtsOperationModal
            ptsStore={ptsStore}
            pts={pts}
            schedule={selectedPtsSchedule}
            onClose={() => setSelectedPtsSchedule(null)}
          />
        )}
      </section>
    </Content>
  );
};

export default observer(PtsDetailsPage);
