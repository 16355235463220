import React from "react";
import s from "./style.module.scss";
import {
  Icons24Alert,
  Icons24LoopLeftLine,
  NavItemButton,
} from "@frontend/assaia-ui";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

type Props = {
  className?: string;
  msg?: string;
  showRefreshBtn?: boolean;
};

export const ErrorMessage: React.FC<Props> = ({
  msg,
  showRefreshBtn = true,
  className,
}) => {
  return (
    <div className={classNames(s.errorMessage, className)}>
      <Icons24Alert className={s.towerIcon} />
      <h1 className={s.title}>
        {msg || (
          <FormattedMessage
            defaultMessage="Assaia ApronAI is under maintenance. {br} Please try later..."
            description="Unavailable error message"
            values={{ br: <br /> }}
          />
        )}
      </h1>
      {showRefreshBtn && (
        <NavItemButton
          className={s.refreshBtn}
          label="Retry"
          icon={<Icons24LoopLeftLine />}
          onClick={() => window.location.reload()}
        />
      )}
    </div>
  );
};
